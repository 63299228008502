<template>
  <section>
    <mdb-modal
      centered
      elegant
      scrollable
      :show="mostrarModal"
      @close="$emit('cerrar', false)"
    >
      <mdb-modal-header>
        <mdb-modal-title>{{ titulo }} </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="pt-0">
        <p>
          Selecciona una fecha para contar cuántas fotos y recibos existen
          pertenecientes a paquetes en estatus Entregado o Devuelto desde
          esa fecha hacia atrás.
        </p>
        <div class="row">
          <div class="col-12 col-sm-5">
            <mdb-input
              v-model="fechaLimite"
              type="date"
              label="Fecha límite"
              class="my-2 outline-date"
              outline
              @change="obtenerFotos()"
            />
          </div>
          <div
            v-if="botonDeshabilitado ||
                $apollo.queries.cantidadFotosParaEliminar.loading"
            class="col-12 mt-3 order-sm-1"
          >
            <CargandoVista clase-adicional="vc-50px" />
          </div>
          <div class="col">
            <p class="text-muted mt-3 mt-sm-0">
              <span class="d-block">
                Cantidad disponible para eliminar:
              </span>
              {{ cantidadFotosParaEliminar }}
              {{ cantidadFotosParaEliminar > 1 ? "Fotos" : "Foto" }}
            </p>
          </div>
        </div>
        <template v-if="cantidadFotosParaEliminar > 0">
          <div class="text-center">
            <p>¿Está seguro que desea limpiar todas las fotos?</p>
            <mdb-btn
              title="Eliminar fotos"
              color="peligroso"
              icon="trash"
              :disabled="botonDeshabilitado"
              class="m-0 mt-3 px-3 text-nowrap"
              @click="mostrarModalConfirmar = !mostrarModalConfirmar"
            >
              Eliminar fotos
            </mdb-btn>
          </div>
        </template>
      </mdb-modal-body>
    </mdb-modal>
    <ConfirmacionEliminar
      :mostrar-modal="mostrarModalConfirmar"
      mensaje="Se eliminarán todas las fotos y recibos de entrega de los paquetes
      en estatus Entregado o Devuelto desde la fecha seleccionada hacia atrás.
      Esta acción es irreversible"
      @cerrar="mostrarModalConfirmar = $event"
      @eliminar="
        confirmadoEliminar();
        mostrarModalConfirmar = false;
      "
    />
    <ModalConfirmarPermiso
      :mostrar-modal="mostrarModalConfirmarClave"
      @alertaMensaje="alertaMensaje = $event"
      @confirmado="eliminarPaquetes"
      @cerrar="mostrarModalConfirmarClave = $event"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>

<script>
import {
  mdbBtn,
  mdbInput,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
} from "mdbvue";
import ConfirmacionEliminar from "@/components/ConfirmacionEliminar.vue";
import ModalConfirmarPermiso from "@/components/ModalConfirmarPermiso.vue";
import AlertaMensaje from "@/components/AlertaMensaje.vue";
import cantidadFotosParaEliminarGql from "@/graphql/paqueteCantidadFotosParaEliminar.gql";
import paqueteLimpiarFotosGql from "@/graphql/paqueteLimpiarFotos.gql";
import CargandoVista from "@/components/CargandoVista.vue";
export default {
  name: "ModalLimpiarFotos",
  components: {
    mdbBtn,
    mdbInput,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    ConfirmacionEliminar,
    ModalConfirmarPermiso,
    AlertaMensaje,
    CargandoVista,
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    titulo: {
      type: String,
      required: false,
      default: "Limpiar fotos de paquetes",
    },
  },
  data() {
    return {
      cantidadFotosParaEliminar: 0,
      existenFotos: false,
      mostrarModalConfirmar: false,
      mostrarModalConfirmarClave: false,
      alertaMensaje: { contenido: "" },
      fechaLimite: this.diaActual(),
      botonDeshabilitado: false,
    };
  },
  watch: {
    mostrarModal(valor) {
      if (valor) {
        this.obtenerFotos();
      }
    },
  },
  methods: {
    diaActual() {
      return new Date().toISOString().split("T")[0];
    },
    obtenerFotos() {
      this.botonDeshabilitado = true;
      this.$apollo.queries.cantidadFotosParaEliminar.refetch();
      this.botonDeshabilitado = false;
    },
    confirmadoEliminar() {
      this.mostrarModalConfirmarClave = true;
    },
    async eliminarPaquetes() {
      this.botonDeshabilitado = true;
      try {
        const {
          data: {
            paqueteLimpiarFotos: { codigo },
          },
        } = await this.$apollo.mutate({
          mutation: paqueteLimpiarFotosGql,
          variables: {
            fechaLimite: `${JSON.parse(JSON.stringify(this.fechaLimite))}T23:59Z`,
          },
        });
        if (codigo === "Correcto") {
          this.alertaMensaje = {
            contenido:
              "Se eliminaron todas las fotos y recibos de entrega de los paquetes con la fecha seleccionada",
            tipo: "correcto",
          };
          this.fechaLimite = this.diaActual()
        } else {
          this.alertaMensaje = {
            contenido: "No se pudo eliminar las fotos de los paquetes",
            tipo: "advertencia",
          };
        }
      } catch (error) {
        this.alertaMensaje = {
          contenido: `Ocurrió un error al eliminar las fotos de los paquetes: ${error.message}`,
          tipo: "error",
        };
      } finally {
        this.botonDeshabilitado = false;
        this.$apollo.queries.cantidadFotosParaEliminar.refetch();
      }
    },
  },
  apollo: {
    cantidadFotosParaEliminar() {
      return {
        query: cantidadFotosParaEliminarGql,
        fetchPolicy: "cache-and-network",
        update: (data) => data.cantidadFotosParaEliminar,
        variables() {
          return {
            fechaLimite: `${this.fechaLimite}T23:59Z`
          };
        },
      };
    },
  },
};
</script>

<style>
</style>