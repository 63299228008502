<template>
  <div class="container-xl px-0 px-sm-3">
    <div class="encabezado-pagina">
      <h2 class="col user-select-none">Configuración</h2>
    </div>
    <CargandoVista
      v-if="$apolloData.queries.usuario.loading"
      clase-adicional="vc-75vh"
    />
    <ul v-else class="lista-configuracion">
      <li
        v-for="(item, index) in listaConfiguracion"
        :key="index"
        :class="{'cursor-not-allowed': item.deshabilitar}"
      >
          <div
          v-if="verificarItem(item)"
          :class="['contenedor-item', {'disabled': item.deshabilitar}]"
          @click="ejecutar(item.accion)"
        >
          <font-awesome-icon :icon="item.icono" class="icono" />
          {{item.titulo}}
        </div>
      </li>
    </ul>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <!-- Modal Cambio clave -->
    <FormularioUsuarios
      :usuarioId="usuarioId"
      :mostrarModal="mostrarModalCambioClave"
      :esCambioDeClave="true"
      @cerrar="mostrarModalCambioClave = false; esEditar = false; esCambioDeClave = false;"
      @alertaMensaje="alertaMensajeAsignar($event)"
    />
    <!-- Modal Editar información de contacto -->
    <mdb-modal
      centered
      elegant
      scrollable
      size="lg"
      :show="mostrarFormularioContacto"
      @close="mostrarFormularioContacto = false; limpiarDatosModalInformacionDeContacto()"
    >
      <mdb-modal-header>
        <mdb-modal-title>Actualizar información de contacto</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div class="row">
          <div class="col-12 col-lg-6">
            <header
              class="user-select-none cursor-pointer"
              title="Agregar nuevo teléfono"
              @click="mostrarNuevoTelefono = !mostrarNuevoTelefono"
            >
              <font-awesome-icon
                icon="plus-circle"
                size="sm"
                class="texto-exitoso"
              />
              Teléfonos
            </header>
            <div class="row align-items-center justify-content-center">
              <div class="col-12 col-md pr-md-0">
                <mdb-input
                  v-if="mostrarNuevoTelefono"
                  v-model.number="nuevoTelefono"
                  type="tel"
                  label="Teléfono"
                  placeholder="Ej: 58426573828"
                  minlength="7"
                  maxlength="13"
                  class="my-2 plus-addon"
                  outline
                  @keyup.enter.native="validarTelefono(nuevoTelefono, true)"
                >
                  <span class="input-group-text md-addon" slot="prepend">+</span>
                </mdb-input>
              </div>
              <div class="col-auto">
                <mdb-btn
                  v-if="mostrarNuevoTelefono"
                  color="exitoso"
                  icon="check"
                  title="Agregar teléfono"
                  class="m-1 py-2 px-3"
                  @click="validarTelefono(nuevoTelefono, true)"
                >
                  Agregar
                </mdb-btn>
              </div>
            </div>
            <!-- Listado de teléfonos -->
            <div v-if="usuario && usuario.telefonos && usuario.telefonos.length">
              <ul class="list-unstyled row mb-2">
                <li v-for="(tel, i) in usuario.telefonos" :key="`tel${i}`" class="col-12 col-sm-6 col-lg-12">
                  <!-- Si se selecciona un teléfono para editar, se sustituirá el número teléfono por un
                  input que permite editarlo  -->
                  <div v-if="editarTelefono">
                    <div
                      v-if="telefonoEditarSeleccionado
                        && telefonoAEditar.telefonoIndice===i"
                    >
                      <mdb-input
                        v-model.number="telefonoAEditar.telefonoNuevo"
                        type="tel"
                        label="Teléfono"
                        minlength="7"
                        maxlength="13"
                        class="my-2 plus-addon"
                        outline
                        @keyup.enter.native="validarTelefono(telefonoAEditar.telefonoNuevo)"
                      >
                        <span class="input-group-text md-addon" slot="prepend">+</span>
                      </mdb-input>
                      <div class="mb-2 text-center">
                        <mdb-btn
                          color="exitoso"
                          icon="sync"
                          title="Actualizar teléfono"
                          class="m-1 py-2 px-3"
                          @click="validarTelefono(telefonoAEditar.telefonoNuevo)"
                        >
                          Actualizar
                        </mdb-btn>
                        <mdb-btn
                          flat
                          dark-waves
                          icon="times"
                          title="Cancelar edición del teléfono"
                          class="m-1 p-2 px-md-3"
                          @click="telefonoEditarSeleccionado = false;"
                        >
                          Cancelar
                        </mdb-btn>
                      </div>
                    </div>
                    <div v-else class="mb-2">
                      <span  class="d-block">
                        +{{tel.numero}}
                      </span>
                      <mdb-btn
                        flat
                        dark-waves
                        icon="pen"
                        title="Editar teléfono"
                        class="m-1 py-1 px-3"
                        @click="telefonoEditarSeleccionado = true;
                          telefonoAEditar = {
                            telefonoIndice: i,
                            telefonoNuevo: tel.numero,
                            telefonoAnterior: tel.numero
                          };"
                      >
                        Editar
                      </mdb-btn>
                      <mdb-btn
                        flat
                        dark-waves
                        icon="trash"
                        title="Eliminar teléfono"
                        class="m-1 py-1 px-3"
                        @click="telefonoRemover(tel.numero)"
                      >
                        Eliminar
                      </mdb-btn>
                    </div>
                  </div>
                  <div v-else>
                    <a
                      :href="`tel:+${tel.numero}`"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      +{{tel.numero}}
                    </a>
                    <div class="d-inline-block">
                      <a
                        :href="`https://wa.me/${tel.numero}`"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn btn-flat btn-bordeado ripple-parent m-1 py-1 px-3"
                      >
                        <font-awesome-icon :icon="['fab', 'whatsapp']" />
                        WA
                      </a>
                      <a
                        :href="`sms:+${tel.numero}`"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn btn-flat btn-bordeado ripple-parent m-1 py-1 px-3"
                      >
                        <font-awesome-icon icon="sms" />
                        Sms
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- Botón para editar teléfonos -->
              <mdb-btn
                block
                flat
                dark-waves
                :icon="botonEditarTelefono.icono"
                :title="botonEditarTelefono.title"
                class="m-0 mr-2 px-3 py-2"
                @click="editarTelefono = !editarTelefono"
              >
                {{ botonEditarTelefono.title }}
              </mdb-btn>
            </div>
            <div
              v-else
              class="text-muted user-select-none"
            >
              Sin teléfonos registrados
            </div>
          </div>
          <!-- Emails -->
          <div class="col-12 col-lg-6 pl-lg-0">
            <header
              class="user-select-none cursor-pointer"
              title="Agregar nuevo email"
              @click="mostrarNuevoEmail = !mostrarNuevoEmail;"
            >
              <font-awesome-icon
                icon="plus-circle"
                size="sm"
                class="texto-exitoso"
              />
              Emails
            </header>
            <div class="row align-items-center justify-content-center">
              <div class="col-12 col-md pr-md-0">
                <mdb-input
                  v-if="mostrarNuevoEmail"
                  v-model.trim="nuevoEmail"
                  type="email"
                  label="Email"
                  class="my-2"
                  outline
                  @keyup.enter.native="validarEmail(nuevoEmail, true)"
                />
              </div>
              <div class="col-auto">
                <mdb-btn
                  v-if="mostrarNuevoEmail"
                  color="exitoso"
                  icon="check"
                  title="Agregar email"
                  class="m-1 py-2 px-3"
                  @click="validarEmail(nuevoEmail, true)"
                >
                  Agregar
                </mdb-btn>
              </div>
            </div>
            <!-- Listado de emails -->
            <div v-if="usuario && usuario.emails && usuario.emails.length">
              <ul class="list-unstyled mb-2">
                <li v-for="(email, i) in usuario.emails" :key="`email${i}`">
                  <div v-if="editarEmail">
                    <div
                      v-if="emailEditarSeleccionado
                        && emailAEditar.emailIndice===i"
                    >
                      <mdb-input
                        v-model.trim="emailAEditar.emailNuevo"
                        type="email"
                        label="Email"
                        class="my-2"
                        outline
                        @keyup.enter.native="validarEmail(emailAEditar.emailNuevo)"
                      />
                      <div class="mb-2 text-center">
                        <mdb-btn
                          color="exitoso"
                          icon="sync"
                          title="Actualizar email"
                          class="m-1 py-2 px-3"
                          @click="validarEmail(emailAEditar.emailNuevo)"
                        >
                          Actualizar
                        </mdb-btn>
                        <mdb-btn
                          flat
                          dark-waves
                          icon="times"
                          title="Cancelar edición del email"
                          class="m-1 p-2 px-md-3"
                          @click="emailEditarSeleccionado = false;"
                        >
                          Cancelar
                        </mdb-btn>
                      </div>
                    </div>
                    <div v-else class="mb-2">
                      <span
                        class="d-block user-select-none"
                        :title="email.email === usuario.usuario
                          ? 'El nombre de usuario debe actualizarse desde la opción Cambio de usuario'
                          : ''"
                      >
                        {{ email.email }}
                        <mdb-badge v-if="email.email === usuario.usuario" pill color="bg-terciario">
                          Nombre de usuario
                        </mdb-badge>
                      </span>
                      <mdb-btn
                        v-if="email.email !== usuario.usuario"
                        flat
                        dark-waves
                        icon="pen"
                        title="Editar email"
                        class="m-1 py-1 px-3"
                        @click="emailEditarSeleccionado = true;
                          emailAEditar = {
                            emailIndice: i,
                            emailNuevo: email.email,
                            emailAnterior: email.email
                          };"
                      >
                        Editar
                      </mdb-btn>
                      <mdb-btn
                        v-if="email.email !== usuario.usuario"
                        flat
                        dark-waves
                        icon="trash"
                        title="Eliminar email"
                        class="m-1 py-1 px-3"
                        @click="emailRemover(email.email)"
                      >
                        Eliminar
                      </mdb-btn>
                    </div>
                  </div>
                  <a
                    v-else
                    target="_blank"
                    :href="`mailto:${email.email}`"
                    class="d-block text-break mt-1 py-1"
                  >
                    {{ email.email}}
                  </a>
                </li>
              </ul>
              <!-- Botón para editar emails -->
              <mdb-btn
                block
                flat
                dark-waves
                :icon="botonEditarEmails.icono"
                :title="botonEditarEmails.title"
                class="m-0 mr-2 px-3 py-2"
              @click="editarEmail = !editarEmail"
              >
                {{ botonEditarEmails.title }}
              </mdb-btn>
            </div>
            <div
              v-else
              class="text-muted user-select-none"
            >
              Sin emails registrados
            </div>
          </div>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <!-- Modal Cambio de usuario -->
    <mdb-modal
      centered
      elegant
      scrollable
      :show="mostrarFormularioCambioUsuario"
      @close="mostrarFormularioCambioUsuario = false; limpiarDatosModalInformacionDeContacto()"
    >
      <mdb-modal-header>
        <mdb-modal-title>Cambio de usuario</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <!-- Listado de emails -->
        <div v-if="usuario && usuario.emails && usuario.emails.length">
          <div v-if="emailEditarSeleccionado">
            <mdb-input
              v-model.trim="emailAEditar.emailNuevo"
              type="email"
              label="Email"
              class="my-2"
              outline
              @keyup.enter.native="validarEmail(emailAEditar.emailNuevo, false, true)"
            />
            <div class="mb-2 text-center">
              <mdb-btn
                color="exitoso"
                icon="sync"
                title="Actualizar email"
                class="m-1 py-2 px-3"
                @click="validarEmail(emailAEditar.emailNuevo, false, true)"
              >
                Actualizar
              </mdb-btn>
              <mdb-btn
                flat
                dark-waves
                icon="times"
                title="Cancelar edición del email"
                class="m-1 p-2 px-md-3"
                @click="emailEditarSeleccionado = false;"
              >
                Cancelar
              </mdb-btn>
            </div>
          </div>
          <div v-else class="mb-2">
            <small class="text-muted user-select-none">Usuario actual</small>
            <span class="d-block">
              {{ usuario.usuario }}
            </span>
            <mdb-btn
              flat
              dark-waves
              icon="pen"
              title="Editar email"
              class="m-1 py-1 px-3"
              @click="emailEditarSeleccionado = true;
                emailAEditar = {
                  emailIndice: 0,
                  emailNuevo: usuario.usuario,
                  emailAnterior: usuario.usuario
                };"
            >
              Editar
            </mdb-btn>
          </div>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <ModalConfirmarPermiso
      :mostrar-modal="mostrarModalConfirmarPermiso"
      @alertaMensaje="alertaMensaje=$event"
      @confirmado="metodoConfirmarPermiso"
      @cerrar="mostrarModalConfirmarPermiso = $event;"
    />
    <ModalLimpiarFotos
      titulo="Limpiar fotos de los paquetes"
      :mostrar-modal="mostrarModalLimpiarFotos"
      @cerrar="mostrarModalLimpiarFotos = $event;"
    />
  </div>
</template>
<script>
import {
  mdbBadge,
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbInput,
} from "mdbvue";
import gql from "graphql-tag";
import AlertaMensaje from "@/components/AlertaMensaje.vue";
import CargandoVista from "@/components/CargandoVista.vue";
import FormularioUsuarios from "../components/FormularioUsuarios.vue";
import usuariosGql from "@/graphql/usuarios.gql";
import usuarioCambiarGql from "@/graphql/usuarioCambiar.gql";
import clienteEditarEmailGql from "@/graphql/clienteEditarEmail.gql";
import clienteCrearEmailGql from "@/graphql/clienteCrearEmail.gql";
import clienteEliminarEmailGql from "@/graphql/clienteEliminarEmail.gql";
import clienteEditarTelefonoGql from "@/graphql/clienteEditarTelefono.gql";
import existeUsuarioGql from "@/graphql/existeUsuario.gql"
import clienteCrearTelefonoGql from "@/graphql/clienteCrearTelefono.gql";
import clienteEliminarTelefonoGql from "@/graphql/clienteEliminarTelefono.gql";
import { leerRoles } from "@/utils/datosToken.js";
import ModalConfirmarPermiso from "../components/ModalConfirmarPermiso.vue";
import ModalLimpiarFotos from "../components/ModalLimpiarFotos.vue";
import {
  validarMail,
  validarTel,
  evitarTelefonosRepetidos,
} from "@/funciones/funciones.js"
import { leerPersonaId } from "@/utils/datosToken.js"
export default {
  name: "Configuración",
  components: {
    mdbBadge,
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput,
    AlertaMensaje,
    CargandoVista,
    FormularioUsuarios,
    ModalConfirmarPermiso,
    ModalLimpiarFotos
  },
  data() {
    return {
      // Teléfonos
      mostrarNuevoTelefono: false,
      editarTelefono: false,
      telefonoEditarSeleccionado: false,
      nuevoTelefono: '',
      telefonoAEditar: {
        telefonoIndice: 0,
        telefonoAnterior: '',
        telefonoNuevo: '',
      },
      // Emails
      mostrarNuevoEmail: false,
      editarEmail: false,
      emailEditarSeleccionado: false,
      nuevoEmail: '',
      emailAEditar: {
        emailIndice: 0,
        emailAnterior: '',
        emailNuevo: '',
      },
      // Otros
      alertaMensaje: {contenido: ''},
      botonDeshabilitado: false,
      listaConfiguracion: [
        {
          accion: 'CambioClave',
          icono: 'lock',
          titulo: 'Cambio de clave',
          deshabilitar: false,
          soloAdmin:false
        },
        {
          accion: 'CambioUsuario',
          icono: 'people-arrows',
          titulo: 'Cambio de usuario',
          deshabilitar: false,
          soloAdmin: false
        },
        {
          accion: 'EditarInformacionContacto',
          icono: 'phone-alt',
          titulo: 'Actualizar información de contacto',
          deshabilitar: false,
        },
        {
          accion: 'LimpiarFotosPaquete',
          icono: 'images',
          titulo: 'Limpiar fotos de paquetes',
          deshabilitar: false,
          soloAdmin:true
        },
      ],
      roles:leerRoles(),
      mostrarModalCambioClave : false,
      mostrarModalConfirmarPermiso : false,
      mostrarModalLimpiarFotos: false,
      usuarioId: '',
      validarValores: {},
      // Editar Información de contacto
      email: '',
      emails: [],
      telefono: '',
      telefonos: [],
      mostrarFormularioContacto: false,
      mostrarFormularioCambioUsuario: false,
    };
  },
  mounted(){
    this.usuarioId = leerPersonaId()
  },
  computed: {
    botonEditarEmails() {
      return this.editarEmail
      ? {icono:'times', title:'Cancelar edición'}
      : {icono:'pen', title:'Editar emails'};
    },
    botonEditarTelefono() {
      return this.editarTelefono
      ? {icono:'times', title:'Cancelar edición'}
      : {icono:'pen', title:'Editar teléfonos'};
    },
  },
  methods: {
    verificarItem(item) {
      return  item.soloAdmin && !this.roles.includes('Admin') ? false : true
    },
    metodoConfirmarPermiso(){
      this.mostrarModalConfirmarPermiso = false;
      this.mostrarModalLimpiarFotos = true;
    },
    // Funcionalidad AlertaMensaje
    mostrarMensajeEnCasoDefault(){
      this.alertaMensaje = {
        contenido: 'Ha ocurrido un error inesperado. Por favor intenta de nuevo',
        tipo: 'error',
      };
    },
    alertaMensajeAsignar(contenido) {
      if(contenido){
        return this.alertaMensaje = {
          contenido: contenido.contenido,
          tipo: contenido.tipo,
        };
      }
    },
    limpiarDatosModalInformacionDeContacto(){
      // Teléfonos
      this.mostrarNuevoTelefono = false;
      this.editarTelefono = false;
      this.telefonoEditarSeleccionado = false;
      this.nuevoTelefono = '';
      this.telefonoAEditar = {
        telefonoIndice: 0,
        telefonoAnterior: '',
        telefonoNuevo: '',
      };
      // Emails
      this.mostrarNuevoEmail = false;
      this.editarEmail = false;
      this.emailEditarSeleccionado = false;
      this.nuevoEmail = '';
      this.emailAEditar = {
        emailIndice: 0,
        emailAnterior: '',
        emailNuevo: '',
      };
    },
    ejecutar(accion) {
      switch (accion) {
        case 'CambioClave':
          // asignar el usuarioID correspondiente a usuarioId
          this.mostrarModalCambioClave = true;
          break;
        case 'CambioUsuario':
          this.mostrarFormularioCambioUsuario = true;
          break;
        case 'EditarInformacionContacto':
          this.mostrarFormularioContacto = true;
          break;
        case 'LimpiarFotosPaquete':
          this.mostrarModalConfirmarPermiso = true;
          break;
        default:
          break;
      }
    },
    // Funcionalidad teléfonos
    validarTelefono(telefono, nuevo){
      if(!nuevo && telefono === this.telefonoAEditar.telefonoAnterior){
        return this.alertaMensaje = {
          contenido:'El teléfono no puede ser idéntico al anterior',
          tipo: 'error'
        };
      }
       if(!telefono || !validarTel(telefono)){
        return this.alertaMensaje= {
          contenido: 'Teléfono no válido',
          tipo: 'error',
        }
       }
      //  Validar teléfono repetido en caso sea de la agencia
       if(evitarTelefonosRepetidos(this.usuario.telefonos, telefono)){
          return (this.alertaMensaje = {
            contenido: 'El teléfono que ingresaste ya se encuentra agregado',
            tipo: 'error'
          })
       }
       if(nuevo) return this.telefonoCrear(telefono)
       this.telefonoEditar(telefono)
    },
    telefonoCrear(tel){
      const telefono = String(tel)
      const id = this.usuarioId;
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: clienteCrearTelefonoGql,
        variables: {
          personaId: id,
          numero: telefono
        },
        update: (store, {data: {telefonoCrear}}) => {
          const data = store.readQuery({
              query: usuariosGql,
              variables: {
                filter:{
                  id
                }
              },
          });
          const msg = telefonoCrear.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido:  'Se ha creado el teléfono exitosamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje = {
                contenido:  'Ha ocurrido un error creando el teléfono. Por favor intenta de nuevo',
                tipo: 'error',
              };
            default:
              this.botonDeshabilitado = false;
              return this.mostrarMensajeEnCasoDefault();
          }
          data.Usuario[0].telefonos.push({numero: telefono, __typename: "Telefono"})
          store.writeQuery({
            query: usuariosGql,
              variables: {
                filter:{
                  id
                }
              },
            data,
          });
          if(!this.botonDeshabilitado){
            this.mostrarNuevoTelefono = false;
            this.nuevoTelefono = '';
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            telefonoCrear: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault()
        this.botonDeshabilitado = false
      })
    },
    telefonoRemover(num){
      const numero = String(num);
      const id = this.usuarioId;
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: clienteEliminarTelefonoGql,
        variables: {
          numero,
          personaId: id
        },
        update: (store, {data: {telefonoEliminar}}) => {
          const msg = telefonoEliminar.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido:  'Se ha removido el teléfono exitosamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje = {
                contenido:  'Ha ocurrido un error eliminando el teléfono. Por favor intenta de nuevo',
                tipo: 'error',
              };
            default:
              this.botonDeshabilitado = false;
              return this.mostrarMensajeEnCasoDefault();
          }
          const data = store.readQuery({
              query: usuariosGql,
              variables: {
                filter:{
                  id
                }
              },
          });
          data.Usuario[0].telefonos = data.Usuario[0].telefonos.filter((e) => {
            return e.numero !== numero;
          });
          store.writeQuery({
            query: usuariosGql,
              variables: {
                filter:{
                  id
                }
              },
            data,
          });
          if(!this.botonDeshabilitado){
            this.editarTelefono = false;
            this.telefonoEditarSeleccionado = false;
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            telefonoEliminar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault()
        this.botonDeshabilitado = false
      })
    },
    telefonoEditar(tel){
      const telefono = JSON.stringify(tel)
      const id = this.usuarioId;
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: clienteEditarTelefonoGql,
        variables: {
          numeroAnterior: String(this.telefonoAEditar.telefonoAnterior),
          personaId: id,
          numeroNuevo: telefono,
        },
        update: (store, {data: {telefonoEditar}}) => {
          const data = store.readQuery({
              query: usuariosGql,
              variables: {
                filter:{
                  id
                }
              },
          });
          const msg = telefonoEditar.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido:  'Se ha actualizado el teléfono exitosamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje = {
                contenido:  'Ha ocurrido un error actualizando el teléfono. Por favor intenta de nuevo',
                tipo: 'error',
              };
            default:
              this.botonDeshabilitado = false;
              return this.mostrarMensajeEnCasoDefault();
          }
          data.Usuario[0].telefonos.forEach(tel => {
            if(this.telefonoAEditar.telefonoAnterior === tel.numero){
              tel.numero = this.telefonoAEditar.telefonoNuevo
            }
          });
          store.writeQuery({
            query: usuariosGql,
              variables: {
                filter:{
                  id
                }
              },
            data,
          });
          if(!this.botonDeshabilitado){
            this.editarTelefono = false;
            this.telefonoEditarSeleccionado = false;
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            telefonoEditar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault();
        this.botonDeshabilitado = false;
      })
    },
    // Funcionalidad emails
    validarEmail(email, nuevo, usuario){
      this.botonDeshabilitado = true;
      // Retorna true si se encuentra ya registrado anteriormente el email
      this.$apollo
        .query({
            query: gql`
              ${existeUsuarioGql}
            `,
            variables: {
              usuario: email
            },
            update: (data) => data.existeUsuario,
            fetchPolicy: "no-cache",
          })
          .then(({data: {existeUsuario}}) => {
              const codigo = existeUsuario.codigo;
              this.botonDeshabilitado = false;
              switch (codigo) {
                case 'Correcto':
                  this.alertaMensaje = {
                    contenido: 'El email especificado ya se encuentra asignado a otro usuario.'+
                    ' Por favor comprueba tus datos',
                    tipo: 'advertencia'
                  };
                  return false
                case 'Fallido':
                  this.validarEmailFormat(email, nuevo, usuario)
                  return true
                default:
                  this.alertaMensaje = {
                    contenido: 'Ha ocurrido un error validando el correo electrónico. Por favor intenta de nuevo',
                    tipo: 'error'
                  }
                  return false
              }
            },
          )
          .catch(() => {
            this.botonDeshabilitado = false;
            this.alertaMensaje = {
              contenido: 'Ha ocurrido un error validando el correo electrónico. Por favor intenta de nuevo',
              tipo: 'error'
            }
            return false
          })
    },
    validarEmailFormat(email, nuevo, usuario){
      if(!nuevo && email === this.emailAEditar.emailAnterior){
        return this.alertaMensaje = {
          contenido:'El email no puede ser idéntico al anterior',
          tipo: 'error'
        };
      }
      if(!email || !validarMail(email)){
        return this.alertaMensaje= {
          contenido: 'Email no válido',
          tipo: 'error',
        }
      }
      if(nuevo) return this.emailCrear(email)
      if(usuario) return this.usuarioCambiar(email)
      this.emailEditar(email)
    },
    emailRemover(email){
      const id = this.usuarioId;
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: clienteEliminarEmailGql,
        variables: {
          email,
          personaId: id,
        },
        update: (store, {data: {emailEliminar}}) => {
          const data = store.readQuery({
              query: usuariosGql,
              variables: {
                filter:{
                  id
                }
              },
          });
          const msg = emailEliminar.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido:  'Se ha removido el correo electrónico exitosamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje = {
                contenido:  'Ha ocurrido un error eliminando el email. Por favor intenta de nuevo',
                tipo: 'error',
              };
            default:
              this.botonDeshabilitado = false;
              return this.mostrarMensajeEnCasoDefault();
          }
          data.Usuario[0].emails = data.Usuario[0].emails.filter((e) => {
            return !email.includes(e.email);
          });
          store.writeQuery({
            query: usuariosGql,
              variables: {
                filter:{
                  id,
                }
              },
            data,
          });
          if(!this.botonDeshabilitado){
            this.editarEmail = false;
            this.emailEditarSeleccionado = false;
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            emailEliminar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault()
        this.botonDeshabilitado = false
      })
    },
    emailEditar(email){
      const id = this.usuarioId;
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: clienteEditarEmailGql,
        variables: {
          emailAnterior: this.emailAEditar.emailAnterior,
          personaId: id,
          emailNuevo: email,
        },
        update: (store, {data: {emailEditar}}) => {
          const data = store.readQuery({
              query: usuariosGql,
              variables: {
                filter:{
                  id
                }
              },
          });
          const msg = emailEditar.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido:  'Se ha actualizado el correo electrónico exitosamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje = {
                contenido:  'Ha ocurrido un error actualizando el email. Por favor intenta de nuevo',
                tipo: 'error',
              };
            default:
              this.botonDeshabilitado = false;
              return this.mostrarMensajeEnCasoDefault();
          }
          data.Usuario[0].emails.forEach(mail => {
            if(this.emailAEditar.emailAnterior === mail.email){
              mail.email = this.emailAEditar.emailNuevo
            }
          });
          store.writeQuery({
            query: usuariosGql,
              variables: {
                filter:{
                  id,
                }
              },
            data,
          });
          if(!this.botonDeshabilitado){
            this.editarEmail = false;
            this.emailEditarSeleccionado = false;
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            emailEditar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault()
        this.botonDeshabilitado = false
      })
    },
    emailCrear(email){
      const id = this.usuarioId;
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: clienteCrearEmailGql,
        variables: {
          personaId: id,
          email
        },
        update: (store, {data: {emailCrear}}) => {
          const data = store.readQuery({
              query: usuariosGql,
              variables: {
                filter:{
                  id
                }
              },
          });
          const msg = emailCrear.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido:  'Se ha creado el correo electrónico exitosamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje = {
                contenido:  'Ha ocurrido un error creando el email. Por favor intenta de nuevo',
                tipo: 'error',
              };
            default:
              this.botonDeshabilitado = false;
              return this.mostrarMensajeEnCasoDefault();
          }
          data.Usuario[0].emails.push({email, __typename: "Email"})
          store.writeQuery({
            query: usuariosGql,
              variables: {
                filter:{
                  id,
                }
              },
            data,
          });
          if(!this.botonDeshabilitado){
            this.mostrarNuevoEmail = false;
            this.nuevoEmail = '';
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            emailCrear: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault()
        this.botonDeshabilitado = false
      })
    },
    usuarioCambiar(email){
      const id = this.usuarioId;
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: usuarioCambiarGql,
        variables: {
          usuarioAnterior: this.emailAEditar.emailAnterior,
          propietarioId: id,
          usuarioNuevo: email,
        },
        update: (store, {data: {usuarioCambiar}}) => {
          const data = store.readQuery({
              query: usuariosGql,
              variables: {
                filter:{
                  id
                }
              },
          });
          const msg = usuarioCambiar.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido:  'Se ha actualizado el usuario exitosamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje = {
                contenido:  'Ha ocurrido un error actualizando el usuario. Por favor intenta de nuevo',
                tipo: 'error',
              };
            default:
              this.botonDeshabilitado = false;
              return this.mostrarMensajeEnCasoDefault();
          }
          data.Usuario[0].emails.forEach(mail => {
            if(this.emailAEditar.emailAnterior === mail.email){
              mail.email = this.emailAEditar.emailNuevo
            }
          });
          data.Usuario[0].usuario = this.emailAEditar.emailNuevo
          store.writeQuery({
            query: usuariosGql,
              variables: {
                filter:{
                  id,
                }
              },
            data,
          });
          if(!this.botonDeshabilitado){
            this.editarEmail = false;
            this.emailEditarSeleccionado = false;
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            usuarioCambiar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault()
        this.botonDeshabilitado = false
      })
    },
  },
  apollo: {
    usuario() {
      return {
        query: usuariosGql,
        variables() {
          return {
            filter:{
              id: leerPersonaId(),
            }
          }
        },
        update: (data) => data.Usuario[0],
        fetchPolicy: "cache-and-network",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.lista-configuracion {
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 1rem;
  padding-left: 0;

  @media screen and (min-width: 576px) {
    display: flex;
    flex-flow: row wrap;
  }

  li {
    cursor: pointer;
    @media screen and (min-width: 576px) {
      align-items: stretch;
      display: flex;
      width: 50%;
    }
    @media screen and (min-width: 1200px) {width: calc(100% / 3);}
  }

  .contenedor-item {
    align-items: center;
    border: 1px solid #C1C6DA;
    border-radius: 7px;
    box-shadow: none;
    color: $terciario-sombra;
    display: flex;
    flex-flow: row nowrap;
    margin: .5rem;
    padding: .75rem 1rem;
    transform: scale(1);
    transition-property: box-shadow, color;
    transition-duration: .25s;
    transition-timing-function: ease;
    user-select: none;

    @media screen and (min-width: 576px) {width: 100%;}

    &:hover {
      box-shadow: 3px 3px 10px #E0E2EC;
      color: $terciario-tinte;
    }
    &.disabled {color: $gris-thead-borde;}

    .icono {
      height: 30px;
      margin-right: .75rem;
      width: 30px;
    }
  }
}
</style>